<template>
  <Chart v-if="!loading" svg :option="options" />
  <Spinner v-else />
</template>
<script>
import { merge } from 'lodash';
import moment from 'moment';
import Chart from '@/components/echarts/Chart';
import Spinner from '@/components/SpinnerLogo';

import chartOptions from './options';

export default {
  components: {
    Chart,
    Spinner
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      const periodStartDate = moment('2023-03-01');
      const periodEndDate = moment('2024-02-01').endOf('month');

      const data = [
        { date: '2022-03-01', consumption: 100 },
        { date: '2022-04-01', consumption: 200 },
        { date: '2022-05-01', consumption: 300 },
        { date: '2022-06-01', consumption: 400 },
        { date: '2022-07-01', consumption: 500 },
        { date: '2022-08-01', consumption: 455 },
        { date: '2022-09-01', consumption: 344 },
        { date: '2022-10-01', consumption: 512 },
        { date: '2022-11-01', consumption: 355 },
        { date: '2022-12-01', consumption: 651 },
        { date: '2023-01-01', consumption: 371 },
        { date: '2023-02-01', consumption: 99 },
        { date: '2023-03-01', consumption: 133 },
        { date: '2023-04-01', consumption: 144 },
        { date: '2023-05-01', consumption: 155 },
        { date: '2023-06-01', consumption: 166 },
        { date: '2023-07-01', consumption: 177 },
        { date: '2023-08-01', consumption: 188 },
        { date: '2023-09-01', consumption: 199 },
        { date: '2023-10-01', consumption: 210 },
        { date: '2023-11-01', consumption: 221 },
        { date: '2023-12-01', consumption: 232 },
        { date: '2024-01-01', consumption: 243 },
        { date: '2024-02-01', consumption: 99 },
        { date: '2024-03-01', consumption: 99 }
      ];

      const monthsArray = Array.from({ length: 12 }, (_, i) => moment(periodStartDate).add(i, 'months').format('MMM YY'));

      const seriesArray = Array.from({ length: 2 }, (_, i) => {
        const startDate = moment(periodStartDate).subtract(i, 'years');
        const endDate = moment(periodEndDate).subtract(i, 'years');

        const seriesName =
          startDate.format('YYYY') === endDate.format('YYYY') ? startDate.format('YYYY') : `${startDate.format('YY')} - ${endDate.format('YY')}`;

        return {
          name: i === 0 ? 'Current' : seriesName,
          type: 'bar',
          data: data.filter(item => moment(item.date).isBetween(startDate, endDate, 'day', '[]')).map(item => item.consumption)
        };
      });

      const options = merge({}, chartOptions, {
        xAxis: {
          name: 'Month',
          type: 'category',
          data: monthsArray,
          axisTick: {
            lineStyle: {
              opacity: 1,
              color: '#6c7985'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: 'kWh'
        },
        series: seriesArray,
        legend: {
          show: true,
          bottom: 10
        }
      });

      return options;
    }
  }
};
</script>
